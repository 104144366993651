import {
	AlreadySubscribedError,
	NotSubscribedError,
} from './StateRegistryErrors';
import State from './State';
import StorageTypes from './StorageTypes';

/**
 * The stateRegistry API of the microkernel.
 */
class StateRegistry {
	constructor() {
		if (StateRegistry._instance) {
			return StateRegistry._instance;
		}

		this._state = new State();
		this._subscriptionQueue = [];
		this._storesToPersist = {};

		StateRegistry._instance = this;
	}

	/**
	 * Create a store with the name `nameOfStore`, `initialData` and `actionFunctions`.
	 * Subscribes callbacks that have been queued before the store existed.
	 * @example
	 * // no persistence
	 * import {stateRegistry} from 'microkernel';
	 * stateRegistry.addStore('dbadExampleStore', {}, {
	 *   'setExampleItem': function(state, parameterObject) {
	 *     return {
	 *       ...state,
	 *       exampleItem = parameterObject.exampleItem
	 *     };
	 *   };
	 * });
	 *
	 * @example
	 * // persistence in localStorage
	 * import {stateRegistry, StorageTypes} from 'microkernel';
	 * stateRegistry.addStore('dbadExampleStore', {}, {
	 *   'setExampleItem': function(state, parameterObject) {
	 *     return {
	 *       Object.assign({}, state),
	 *       exampleItem = parameterObject.exampleItem
	 *     };
	 *   },
	 * 	 {
	 * 	   {storageType: StorageTypes.LOCAL_STORAGE}
	 *   };
	 * });
	 *
	 * @param {string} nameOfStore - The name of the store.
	 * @param {Object} initialData - The initial state of the store.
	 * @param {Object<string, function>} actionFunctions - Object of functionName/function pairs containing the action functions usable on this store.
	 * Please note, that for persistable stores, all actionFunctions have to be implemented with vanilla ES5-compatible Javascript,
	 * else it will not be possible to trigger these action on a store, which has been restored from persistence.
	 * @param {Object<Storage>|boolean} persist - whether the store shall be persisted, the storage to use, respectively
	 * @returns {boolean} - true if a new store could be created; else: false
	 */
	addStore(nameOfStore, initialData, actionFunctions, persist = false) {
		try {
			this._state.setStore(nameOfStore, initialData, actionFunctions);
			this._processSubscriptionQueue(nameOfStore);

			if (persist) {
				const storageToUse = persist.storageType
					? persist.storageType
					: StorageTypes.SESSION_STORAGE;
				const timestampFromPersistence = persist.timestamp
					? persist.timestamp
					: false;
				this._storesToPersist[nameOfStore] = {
					actions: actionFunctions,
					storageType: storageToUse,
					timestamp: timestampFromPersistence,
				};
			}

			return true;
		} catch (error) {
			return false;
		}
	}

	/**
	 * get the state of a store
	 * @param {String} nameOfStore - the name of the store to get
	 * @returns {Object} current state of the store
	 */
	getState(nameOfStore) {
		if (
			this._state &&
			this._state.getStore &&
			typeof this._state.getStore === 'function' &&
			this._state.getStore(nameOfStore)
		) {
			return this._state.getStore(nameOfStore).getState();
		}
		return {};
	}

	/**
	 * get the actions of a persistable store
	 * @param {String} nameOfStore - name of the store to get actions of
	 * @returns {Object} action functions of the store in the form actionString: function
	 */
	getPersistableActions(nameOfStore) {
		return this._storesToPersist[nameOfStore].actions;
	}

	/**
	 * whether the store has been recovered from persistence
	 * @param {String} nameOfStore - name of store
	 * @returns {boolean} whether the store has been recovered from persistence
	 */
	getTimestampFromPersistence(nameOfStore) {
		return this._storesToPersist[nameOfStore].timestamp;
	}

	/**
	 * Subscribe `callbackFunction` to the store with the name `nameOfStore`.
	 * The given callback is called, every time the state of the store changes.
	 * If the store does not exist it queues the subscription until the store is added.
	 * @example
	 * stateRegistry.subscribeToStore('dbadExampleStore', function (state) {
	 *   console.log('current state of example store: ', state);
	 * });
	 * @param {string} nameOfStore - The name of the store.
	 * @param {function} callbackFunction - A named callback.
	 * @returns {void}
	 * @throws {AlreadySubscribedError} Will throw an error if `callbackFunction` has already been subscribed.
	 */
	subscribeToStore(nameOfStore, callbackFunction) {
		const store = this._state.getStore(nameOfStore);

		if (store !== null) {
			store.subscribe(callbackFunction);
			callbackFunction(store.getState(), nameOfStore);
		} else {
			this._enqueueSubscription(nameOfStore, callbackFunction);
		}
	}

	/**
	 * Trigger the action `nameOfAction` in the store with the name `nameOfStore`.
	 * The action optionally carries a `parameterObject`.
	 * @example
	 * stateRegistry.triggerAction('dbadExampleStore', 'setExampleItem', {
	 *   exampleItem: exampleItem
	 * });
	 * @param {string} nameOfStore - The name of the store.
	 * @param {string} nameOfAction - The name of the action.
	 * @param {Object} parameterObject - The (optional) payload of the action.
	 * @returns {void}
	 */
	triggerAction(nameOfStore, nameOfAction, parameterObject = {}) {
		const store = this._state.getStore(nameOfStore);

		if (store !== null) {
			store.triggerAction(nameOfAction, parameterObject);
			if (
				this._storesToPersist[nameOfStore] &&
				this._storesToPersist[nameOfStore].timestamp
			) {
				this._storesToPersist[nameOfStore].timestamp = Date.now();
			}
		} // else: remain silent
	}

	/**
	 * Unsubscribe `callbackFunction` from the store with the name `nameOfStore`.
	 * @example
	 * const subscribedFunction = function (state) {
	 *   console.log('current state of example store: ', state);
	 * }
	 * stateRegistry.unsubscribeFromStore('dbadExampleStore', subscribedFunction);
	 * @param {string} nameOfStore - The name of the store.
	 * @param {function} callbackFunction - A named callback.
	 * @throws {NotSubscribedError} Will throw an error if `callbackFunction` has not been subscribed before.
	 * @returns {void}
	 */
	unsubscribeFromStore(nameOfStore, callbackFunction) {
		const store = this._state.getStore(nameOfStore);

		if (store !== null) {
			store.unsubscribe(callbackFunction);
		} else {
			this._dequeueSubscription(nameOfStore, callbackFunction);
		}
	}

	get storesToPersist() {
		return this._storesToPersist;
	}

	/**
	 * Remembers `callbackFunction` in an array that is associated with `nameOfStore`.
	 * @param {string} nameOfStore - The name of the store.
	 * @param {function} callbackFunction - A named callback.
	 * @returns {void}
	 * @throws {AlreadySubscribedError} Will throw an error if `callbackFunction` has already been subscribed.
	 * @private
	 */
	_enqueueSubscription(nameOfStore, callbackFunction) {
		if (this._subscriptionQueue[nameOfStore] instanceof Array) {
			const index = this._subscriptionQueue[nameOfStore].indexOf(
				callbackFunction,
			);

			if (index !== -1) {
				throw new AlreadySubscribedError(callbackFunction.name);
			}

			this._subscriptionQueue[nameOfStore].push(callbackFunction);
		} else {
			this._subscriptionQueue[nameOfStore] = [callbackFunction];
		}
	}

	/**
	 * Subscribes all callback functions that are queued for the store with the name `nameOfStore`.
	 * @param {string} nameOfStore - The name of the store.
	 * @return {void}
	 * @private
	 */
	_processSubscriptionQueue(nameOfStore) {
		if (this._subscriptionQueue[nameOfStore] instanceof Array) {
			const subscriptionQueueLength = this._subscriptionQueue[nameOfStore]
				.length;

			for (let i = 0; i < subscriptionQueueLength; i += 1) {
				this.subscribeToStore(
					nameOfStore,
					this._subscriptionQueue[nameOfStore][i],
				);
			}

			this._subscriptionQueue[nameOfStore] = [];
		}
	}

	/**
	 * Deletes `callbackFunction` from the array that is associated with `nameOfStore`.
	 * @param {string} nameOfStore - The name of the store.
	 * @param {function} callbackFunction - A named callback.
	 * @returns {void}
	 * @throws {NotSubscribedError} Will throw an error if `callbackFunction` has not been subscribed before.
	 * @private
	 */
	_dequeueSubscription(nameOfStore, callbackFunction) {
		if (typeof this._subscriptionQueue[nameOfStore] === 'undefined') {
			throw new NotSubscribedError(callbackFunction.name);
		}

		const index = this._subscriptionQueue[nameOfStore].indexOf(
			callbackFunction,
		);

		if (index === -1) {
			throw new NotSubscribedError(callbackFunction.name);
		} else {
			this._subscriptionQueue[nameOfStore].splice(index, 1);
		}
	}
}

export default StateRegistry;
